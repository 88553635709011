import React from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";

const ESignAlreadyDoneModal = ({ open, handleClose }) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Notification</DialogTitle>
            <DialogContent>
                <p>Client eSign is already done.</p>
            </DialogContent>
        </Dialog>
    )
}

export default ESignAlreadyDoneModal