import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../assets/images/tramo-logo.svg';
import global from '../../../assets/scss/global.module.scss'
import useronboard from './UserOnboard.module.scss';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
 import "primereact/resources/themes/lara-light-indigo/theme.css";
 import '../../../assets/scss/stepper.css'


import UserAddharCard from './UserAddharCard';
import UserPanCard from './UserPanCard';
import UserGstin from './UserGstin';
import UploadKycDocument from './UploadKycDocument';
import AddBankAccount from './AddBankAccount';
import DepartmentInformation from './DepartmentInformation'
import UploadKycDocumentFinal from './UploadKycDocumentFinal';
import AddBankAccountOld from './AddBankAccountOld';
import DepartmentInformationFinal from './DepartmentInformationFinal';
import fetcher from '../../../utils/fetcher';
import { ApiUrls , PageUrls} from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { isLoggedin } from '../../../utils/authGuard';
import { useNavigate } from 'react-router-dom';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const UserOnboardStepper = () => {
    const navigate = useNavigate();
    const stepperRef = useRef(null);
    const { token } = useParams();
    const [userData, setUserData] = useState(null);
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const [activeStep, setActiveStep] = useState(0); // State to track the active step
    const [isAadharVerified, setIsAadharVerified] = useState(false);
    const [isPanVerified, setIsPanVerified] = useState(false);
    const [showPanView, setPanVisibility] = useState(false);
    const [showStep2Next, setNextVisibility] = useState(false);
    const [tabStep, setTabStep] = useState(0); 
    const [isEdited, setIsEdited] = useState(false);

    const steps = [
        'Personal Information',
        'Business Information',
        'Upload KYC Document',
        'Banking Information',
        'Department Information',
      ];

      const fetchUserData = async () => {
        try {
          const res = await fetcher.get(ApiUrls.LEAD_REGISTER_DETAIL);
          setUserData(res.response);
          setActiveStep(res.response.completedStep || 0);
         
         if (res.response.completedStep < 3) {
          setTabStep(0);
        } else  if (res.response.completedStep === 3) {
          setTabStep(1);
        } else if (res.response.completedStep === 4) {
          setTabStep(2);
        } else if (res.response.completedStep === 5) {
          setTabStep(3);
        } else if (res.response.completedStep === 6) {
          setTabStep(4);
        }else if (res.response.completedStep === 7) {
            navigate(PageUrls.USER_REGISTRATION); 
        }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
    
      useEffect(() => {
        if (isEdited) {
          fetchUserData();
          setIsEdited(false);
        }
      }, [isEdited]);
    
    const renderStepContent = (step) => {
        switch (step) {
          case 1:
            return (
                <div> 
                  <UserAddharCard aadharListener={handleAadharResponse} userData={userData}/>
                {showPanView ? (
                    <UserPanCard panListener={handlePanResponse} userData={userData}/>
                ) : (
                    <div className={useronboard.pan_details}>
                        <h3><i className={useronboard.gray_text}>B</i> Pan Verification</h3>
                    </div>
                )}

                {showStep2Next && (  <div className={useronboard.submit_text_bg_}>
                        <div className={global.row}>
                            <div className={global.col_s_2}>
                                  <input type='button'  onClick={() => handleNext(1)} className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Next" />
                            </div>
                        </div>
                    </div>)}
                </div>
            );
          case 2:
            return (
              <div> 
              <UserAddharCard aadharListener={handleAadharResponse} userData={userData}/>
            {showPanView ? (
                <UserPanCard panListener={handlePanResponse} userData={userData}/>
            ) : (
                <div className={useronboard.pan_details}>
                    <h3><i className={useronboard.gray_text}>B</i> Pan Verification</h3>
                </div>
            )}

            {showStep2Next && (  <div className={useronboard.submit_text_bg_}>
                    <div className={global.row}>
                        <div className={global.col_s_2}>
                              <input type='button'  onClick={() => handleNext(1)} className={`${global.submit_primary_btn} ${global.display_inline_blk}`} value="Next" />
                        </div>
                    </div>
                </div>)}
            </div>
            );
          case 3:
            return <UserGstin nextStep={handleNext} userData={userData} />;
          case 4:
            return <UploadKycDocumentFinal nextStep={handleNext} userData={userData} />;
          case 5:
            return <AddBankAccount nextStep={handleNext} userData={userData} />;
          case 6:
            return <DepartmentInformationFinal nextStep={handleNext} userData={userData}  setIsEdited={setIsEdited} />;
          default:
            return null;
        }
      };

    const handleAadharResponse = () => {
        //setAadhar is verified 
        setIsAadharVerified(true)
        //set Pan Visibility
        setPanVisibility(true)
      };

      const handleReset = () => {
        setActiveStep(0);
        setCompletedSteps(new Set());
      };
      const handlePanResponse = () => {
        setIsPanVerified(true)
        setNextVisibility(true)
      };
     
    const handleNext = (step = null) => {
        if (step) {
            setActiveStep(step+2);
            setTabStep(step);
            return;
        }
        setActiveStep((prevStep) => prevStep + 1);
        setTabStep((prevStep) => prevStep + 1);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('auth', JSON.stringify({token: token}));
        }
        if (isLoggedin()) {
            fetchUserData(); 
        }
    }, []);
    
    
    return (
        <>
            <div className={global.top_header}>
                <div className={global.container}>
                    <h1><img src={logo} alt="Tramo" /> </h1>
                </div>
            </div>

            <div className={useronboard.bg_color}>
                <div className={global.container}>


                    <div className={useronboard.complete_registration}>
                        <h2>Completing Your Registration</h2>


                        <div className="card flex justify-content-center">
                        <Box className={`${global.stepperContainer}`} sx={{ width: '100%'}}>
      <Stepper activeStep={tabStep} sx={{ width: '80%', margin: '0 0 20px 0'}}>
        {steps.map((label, index) => {
          const stepClass = tabStep === index ? global.activeStep : '';
          const completedClass = completedSteps.has(index) ? global.completedStep : '';

          return (
            <Step key={label} className={`${global.step} ${stepClass} ${completedClass}`} completed={completedSteps.has(index)}>
            <StepLabel>{label}</StepLabel>
          </Step>
          );
        })}

      </Stepper>
      {tabStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
          {renderStepContent(activeStep)}
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            {/* <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button> */}
            <Box sx={{ flex: '1 1 auto' }} />
            {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleNext} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button> 
          </Box>
        </React.Fragment>
      )}
    </Box>
                        </div>


                        <div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default UserOnboardStepper