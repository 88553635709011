import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Button,
    TextField,
} from "@mui/material";
import fetcher from "../../utils/fetcher";
import jsPDF from 'jspdf';
import ESignCompleteModal from "../../components/modals/ESignCompleteModal";
import { useParams } from 'react-router-dom';
import { ApiUrls } from "../../utils/constants";
import { getLocation, getIpAddress, getDeviceInfo, getOperatingSystem } from "../../utils/getLocation";
import Loader from '../../utils/Loader';
import DOMPurify from 'dompurify';
import ESignAgreementPreview from "./ESignAgreementPreview";
const ESignOtpVerification = () => {
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
    const [otp, setOtp] = useState(""); // Single OTP field
    const [isOpen, setIsOpen] = useState(false)
    // const [aadharData, setAadhaarData] = useState()
    const [aadhaarImageUrl, setAadhaarImageUrl] = useState({});
    const [otpError, setOTPError] = useState("");
    const [loading, setLoading] = useState(false);
    const [isPreview, setIsPreview] = useState(false)
    const [clientLatitude, setClientLatitude] = useState("")
    const [clientLongitude, setClientLongitude] = useState("")
    const [content, setContent] = useState("")
    const [updatedContent, setUpdatedContent] = useState("");
    const [name, setName] = useState("")
    const [timestamp, setTimestamp] = useState('')
    const [location, setLocation] = useState('')
    const [leadId, setLeadId] = useState()
    const [ip, setIp] = useState("")
    const [browser, setBrowser] = useState("")
    const [operatingSystem, setOperatingSystem] = useState("")
    const [leadBusinessName, setLeadBusinessName] = useState("")
    const [leadBusinessEmail, setLeadBusinessEmail] = useState("")

    const params = useParams();
    const { aadherNo, phoneNo, refId, pageOpenTime } = params
    useEffect(() => {
        const savedExpirationTime = localStorage.getItem("otpExpirationTime");
        const currentTime = Math.floor(Date.now() / 1000);
        if (savedExpirationTime && savedExpirationTime > currentTime) {
            setTimeLeft(savedExpirationTime - currentTime);
        } else {
            const newExpirationTime = currentTime + 600;
            localStorage.setItem("otpExpirationTime", newExpirationTime);
            setTimeLeft(600);
        }
        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    localStorage.removeItem("otpExpirationTime");
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);
    const fetchUserData = async () => {
        try {
            setLoading(true)
            const data = await fetcher.get(ApiUrls.LEAD_REGISTER_DETAIL);
            if (data?.status === 200) {
                //const imageUrl = `data:image/jpeg;base64,${data.response.leadAadhaarDetails.profilePic}`;
                const leadUserId = data.response.leadUserId
                setLeadId(leadUserId)
                setLeadBusinessName(data.response.leadBusinessDetails.name)
                setLeadBusinessEmail(data.response.email)
                const res = await fetcher.get(`${ApiUrls.GET_LEAD_SAVE_ESIGN_CONTENT}?leadUserId=${leadUserId}`)
                if (res?.status === 200) setContent(res.response)
            } else {
                console.error("Failed to fetch lead details.");
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };
    const handleAddClose = () => {
        setIsOpen(false);
    };
    const verifyAadhaarOtp = async (otp) => {
        try {
            setOTPError("")
            setLoading(true)
            const location = await getLocation();
            const ipAddress = await getIpAddress();
            setIp(ipAddress)
            const deviceInfo = getDeviceInfo();
            const { browser } = deviceInfo
            setBrowser(browser)
            const operatingSystem = getOperatingSystem();
            setOperatingSystem(operatingSystem)
            setClientLongitude(location.longitude)
            setClientLatitude(location.latitude)
            const payload = {
                otp: otp,
                otpReferenceID: refId,
                latitude: location.latitude,
                longitude: location.longitude,
                consent: "Y"
            };
            const aadhaarRes = await fetcher.post(ApiUrls.LEAD_VERIFY_AADHAR_OTP, payload);


            if (aadhaarRes?.status === 200 && aadhaarRes?.response.status === 200) {
                // setAadhaarData(aadhaarRes.response.response);
                const imageUrl = `data:image/jpeg;base64,${aadhaarRes.response.response.data.profilePic}`;
                setAadhaarImageUrl(imageUrl);
                setName(aadhaarRes?.response?.response?.data?.fullName)
                setTimestamp(aadhaarRes?.response?.response?.timestamp)
                setLocation(aadhaarRes?.response?.response?.location)
                setIsOpen(true)
                await fetchUserData()
            } else {
                setOTPError(aadhaarRes?.response?.message || aadhaarRes?.message);
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleSubmit = async () => { await verifyAadhaarOtp(otp) }
    useEffect(() => {
        const fetchAndUpdateHtml = async () => {
            await updateHtml();
        };
        fetchAndUpdateHtml();
    }, [content]);
    const updateHtml = async () => {
        if (content !== "") {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, "text/html");
            const aadharDiv = doc.getElementById("aadharData");
            // const secondPartyDiv = doc.getElementById("secondPartyDetails")
            const entityTd = doc.getElementById("entityTd");
            const signatoryTd = doc.getElementById("signatoryTd");
            const emailTd = doc.getElementById("emailTd");
            const openedOnTd = doc.getElementById("openedOnTd");
            const signedOnTd = doc.getElementById("signedOnTd");
            const latitudeTd = doc.getElementById("latitudeTd");
            const longitudeTd = doc.getElementById("longitudeTd");
            const osTd = doc.getElementById("osTd");
            const ipTd = doc.getElementById("ipTd");
            const deviceTypeTd = doc.getElementById("deviceTypeTd");
            const signTypeTd = doc.getElementById("signTypeTd");
            const photoCapturedTd = doc.getElementById("photoCapturedTd");
            if (aadharDiv) {
                aadharDiv.innerHTML = `
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            background-color: #f0f8ff;
                            padding: 10px;
                            border: 1px solid #ddd;
                            border-radius: 8px;
                        "
                    >
                        <img
                            src="${aadhaarImageUrl}"
                            alt="Profile"
                            style="
                                width: 50px;
                                height: 50px;
                                border-radius: 50%;
                                margin-right: 10px;
                            "
                        />
                        <div style="flex: 1;">
                            <p style="margin: 0;">
                                <strong>Signed by:</strong> ${name}
                            </p>
                            <p style="margin: 0;">
                                <strong>Date:</strong> ${timestamp}
                            </p>
                            <p style="margin: 0;">
                                <strong>Location:</strong> ${location}
                            </p>
                            <p style="margin: 0;">
                                <strong>Lat.:</strong> ${clientLatitude}
                                <strong>Log:</strong> ${clientLongitude}
                            </p>
                        </div>
                        <div style="text-align: center; margin-left: 10px;">
                            <img
                                src="/path-to-validity-icon.png"
                                alt="Valid"
                                style="max-width: 40px; margin-bottom: 5px;"
                            />
                            <p style="margin: 0; font-size: 14px; color: green;">
                                Signature valid
                            </p>
                        </div>
                    </div>
                `;
            }
            if (entityTd) entityTd.innerHTML = leadBusinessName;
            if (signatoryTd) signatoryTd.innerHTML = name;
            if (emailTd) emailTd.innerHTML = leadBusinessEmail;
            if (openedOnTd) openedOnTd.innerHTML = pageOpenTime;
            if (signedOnTd) signedOnTd.innerHTML = timestamp;
            if (latitudeTd) latitudeTd.innerHTML = clientLatitude;
            if (longitudeTd) longitudeTd.innerHTML = clientLongitude;
            if (osTd) osTd.innerHTML = `${operatingSystem}, ${browser}`;
            if (ipTd) ipTd.innerHTML = ip;
            if (deviceTypeTd) deviceTypeTd.innerHTML = operatingSystem;
            if (signTypeTd) signTypeTd.innerHTML = "Aadhaar Authentication";
            if (photoCapturedTd) {
                photoCapturedTd.innerHTML = `<img src="${aadhaarImageUrl}" alt="Second Party"
                 style="display: "block",
                        margin: "auto",
                        width: 80px; 
                        height: 80px;
                        border-radius: 50%;" 
                        />`;
            }
            // if (secondPartyDiv) {
            //     secondPartyDiv.innerHTML = `
            //     <div id="secondPartyDetails">
            //     <strong>Entity Name:</strong> ${leadBusinessName} <br />
            //     <strong>Signatory Name:</strong> ${name} <br />
            //     <strong>Email ID:</strong> ${leadBusinessEmail} <br />
            //     <strong>Document Opened on:</strong> ${pageOpenTime} <br /> 
            //     <strong>Document Signed on:</strong> ${timestamp} <br />
            //     //  <strong>Signature Location:</strong> New York City, USA <br /> 
            //     <strong>Latitude:</strong>${clientLatitude} <br />
            //     <strong>Longitude:</strong> ${clientLongitude} <br />
            //     <strong>Operating System & Browser:</strong> ${operatingSystem},${browser}<br />
            //     // <strong>Browser Version:</strong> 118.0.1 <br />
            //     <strong>IP Address:</strong> ${ip} <br />
            //     <strong>Device Type:</strong>${operatingSystem} <br />
            //     <strong>Sign Type:</strong> Aadhaar Authentication <br />
            //     <img
            //         src="${aadhaarImageUrl}"
            //         alt="Second Party"
            //         style= "
            //             display: block;
            //             margin: auto;
            //             width: 80px;
            //             height: 80px;
            //             borderRadius: 50%;
            //             "
            //     />
            //     </div>`
            // }
            const updatedHtml = doc.body.innerHTML;
            const payload = {
                esignTemplate: updatedHtml,
                leadUserId: leadId
            };
            const saveResponse = await fetcher.post(
                ApiUrls.LEAD_SAVE_ESIGN_CONTENT, payload)
            if (saveResponse?.status === 200) {
                console.log("eSign template saved successfully.");
            } else {
                alert("Failed to save eSign template.");
            }
            setUpdatedContent(updatedHtml);
        }
    }
    const sanitizedContent = DOMPurify.sanitize(updatedContent);
    const blobToFile = async (theBlob, fileName) => {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    const handleFileUpload = async (fileName, leadUserId, pdfBlob) => {

        try {
            const file = await blobToFile(pdfBlob, fileName)
            const res = await fetcher.post(`${ApiUrls.GET_PREFETCH_URL}?fileName=${fileName}`);
            if (res.status === 200) {
                const uploadUrl = res.response;
                await fetcher.putFile(uploadUrl, file);
                // if (uploadRes.status === 200) {
                //   console.log("File uploaded successfully to S3");
                const response = await fetcher.post(ApiUrls.USER_ONBOARD)
                if (response.status === 200) {
                    console.log("User is onbord");
                } else {
                    console.error("Error while onboarding user");
                }
                // } else {
                //   console.error("Failed to upload file to S3", uploadRes.statusText);
                // }
            }
        } catch (err) {
            console.error("Error in file upload", err);
        }
    };
    useEffect(() => {
        if (updatedContent !== "") {

            const sanitizedHtml = DOMPurify.sanitize(updatedContent);
            const parser = new DOMParser();
            const doc = parser.parseFromString(sanitizedHtml, "text/html");
            const contentToPrint = doc.body;
            const pdf = new jsPDF({
                orientation: "p",
                unit: "mm",
                format: "a4",
                compressPdf: true,
            });
            pdf.html(contentToPrint, {
                callback: async (doc) => {
                    const pdfBlob = doc.output("blob");
                    const fileName = `esignlead${leadId}.pdf`;
                    await handleFileUpload(fileName, leadId, pdfBlob);
                },
                x: 10,
                y: 10,
                width: 190,
            });
            // }
        }
    }, [sanitizedContent])

    if (loading) return <Loader />;
    if (otpError) return <Typography>{otpError}</Typography>;
    return (<>
        {!isPreview &&
            <Box sx={{ maxWidth: 600, margin: "auto", p: 4, textAlign: "center" }}>
                {/* Header Section */}
                <Typography variant="h6" gutterBottom>
                    Session expires in {formatTime(timeLeft)}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    By clicking this checkbox and the sign document, I voluntarily agree to proceed.
                </Typography>

                {/* Main Content */}
                <Box sx={{ mt: 4, p: 2, border: "1px solid #ccc", borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom>
                        Complete Your eSign
                    </Typography>
                    <Box sx={{ mt: 3, textAlign: "left" }}>
                        <Typography variant="h6" gutterBottom>
                            Aadhaar Verification
                        </Typography>

                        <Typography variant="body1">Aadhaar Number: {aadherNo}</Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                            Enter OTP:
                        </Typography>

                        {/* OTP Input Field */}
                        <TextField
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            inputProps={{
                                maxLength: 6, // Allow OTP to be a 6-digit number
                                style: { textAlign: "center" },
                            }}
                            sx={{ width: "100%" }}
                        />

                        <Typography variant="caption" sx={{ mt: 2, display: "block" }}>
                            OTP will be sent to {phoneNo}
                        </Typography>
                    </Box>
                </Box>

                {/* Submit Button */}
                <Button
                    variant="contained"
                    sx={{ mt: 3 }}
                    fullWidth
                    onClick={handleSubmit}
                    disabled={otp.length !== 6} // Disable the button if OTP is not complete
                >
                    Submit
                </Button>
                {isOpen && (<ESignCompleteModal open={isOpen}
                    onClose={handleAddClose}
                    setIsOpen={setIsOpen}
                    setIsPreview={setIsPreview}
                />)}
            </Box>}
        {isPreview && (<ESignAgreementPreview sanitizedContent={sanitizedContent} />
        )}
    </>
    );
};

export default ESignOtpVerification;
