import React, { useState, useEffect } from "react";
import global from "../../assets/scss/global.module.scss";
import UserRegistrations from "./UserRegistrations.module.scss";
import * as Yup from "yup";
import { useFormik } from "formik";
import fetcher from "../../utils/fetcher";
import { ApiUrls, PageUrls } from "../../utils/constants";
import Loader from "../../utils/Loader";

const MobileOTP = ({ mobileOTPEvent, leadUser }) => {
  const [otpState, setOtpState] = useState("otp"); // Tracks OTP state
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const [onboardingLink, setOnboardingLink] = useState("https://www.tramo.in/");
  const [resendTimer, setResendTimer] = useState(59);

  useEffect(() => {
    if (resendTimer > 0) {
      const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  // Handles OTP input
  const handleOtpChange = (value, index) => {
    const newOtp = [...formik.values.otp];
    newOtp[index] = value;
    formik.setFieldValue("otp", newOtp);

    // Move focus to the next input if the current one is filled and it's not the last input
    if (value !== "" && index < newOtp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    // Move focus to the previous input if the value is deleted and it's not the first input
    if (value === "" && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  // Handle OTP submission
  const handleOtpSubmit = async () => {
    setLoading(true); // Start loading
    // Prepare the data to send in the request body
    const otpArr = formik.values.otp.toString().replaceAll(",", "");
    const requestData = {
      identifier: leadUser.mobile, // Replace with the actual identifier (e.g., phone number)
      otp: otpArr, // Replace with the actual OTP value
    };
    //submit otp
    //if status 200 then show success Dialog

    // const resOtp = await fetcher.post(ApiUrls.LEAD_VERIFY_OTP, requestData);
    // if (resOtp?.status === 200) {
    //   setOtpState('success');
    // }else if(resOtp?.status !== 200) {
    //   setErrorMsg(resOtp.message);
    // }else{
    //   setOtpState('failure');
    // }

    try {
      const resOtp = await fetcher.post(ApiUrls.LEAD_VERIFY_OTP, requestData);
      if (resOtp?.status === 200) {
        setOnboardingLink(resOtp?.response)
        setOtpState("success");
      } else {
        setErrorMsg(resOtp.message || "Failed to verify OTP");
        //setOtpState("failure");
      }
    } catch (error) {
      setErrorMsg("An error occurred during OTP verification");
      //setOtpState("failure");
    }
    setLoading(false); // End loading
  };

  const otpValidationSchema = Yup.object({
    otp: Yup.array()
      .of(
        Yup.string()
          .matches(/^[0-9]{1}$/, "Must be a digit")
          .required("6 Digit opt required.")
      )
      .min(6, "OTP must be exactly 6 digits")
      .max(6, "OTP must be exactly 6 digits"),
  });

  const formik = useFormik({
    initialValues: {
      otp: ["", "", "", "", "", ""],
    },
    validationSchema: otpValidationSchema,
    onSubmit: handleOtpSubmit,
  });

  const handleFailedSuccessState = () => {
    const isSuccess = true;

    if (isSuccess) {
      mobileOTPEvent();
    } else {
      //failedCase
    }
  };

  const handleOtpSubmit1 = () => {
    const isOtpCorrect = true;

    if (isOtpCorrect) {
      setOtpState("success");
    } else {
      setOtpState("failure");
    }
  };

  const handleFailedSuccessState1 = () => {
    const isSuccess = true;

    if (isSuccess) {
      mobileOTPEvent();
    } else {
      //failedCase
    }
  };

  // Function to reset and resend OTP
  const resetOtp = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetcher.get(
        `${ApiUrls.LEAD_GENRATE_OTP}/${leadUser.mobile}`,
        { identifier: leadUser.email }
      );
      if (response.status === 200) {
        setErrorMsg(response.message);
        setResendTimer(59); // Reset timer
      } else {
        setErrorMsg(response.message);
      }
    } catch (error) {
      setErrorMsg("Error in sending OTP");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <>
      <div className={global.overlay}>
        <div className={UserRegistrations.opt_screen}>
          {loading && <Loader />}
          {otpState === "otp" && (
            <>
              <h2>Enter Mobile OTP</h2>
              <div className={`${global.mar_top_16} ${global.text_center}`}>
                Verification code sent to{" "}
                <span className={UserRegistrations.blue_text}>
                  {leadUser.mobile}
                </span>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div
                  className={`${global.row} ${global.d_flex} ${global.d_flex_center} ${global.mar_top_16}`}
                >
                  {formik.values.otp.map((_, index) => (
                    <span
                      key={index}
                      className={`${global.col_s_2} ${UserRegistrations.otp_right_1}`}
                    >
                      <input
                        id={`otp-input-${index}`}
                        className={global.form_control}
                        type="text"
                        maxLength="1"
                        inputMode="numeric"
                        value={formik.values.otp[index]}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                      />
                    </span>
                  ))}
                </div>

                {/* Show validation error for the entire OTP */}
                {formik.errors.otp && formik.touched.otp ? (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    Please enter a valid 6-digit OTP.
                  </div>
                ) : errorMsg ? (
                  <div
                    className={`${global.error_message} ${global.text_right} ${global.mar_top_8}`}
                  >
                    {errorMsg}
                  </div>
                ) : null}

                <div
                  className={`${global.form_group} ${global.text_left} ${global.mar_top_8}`}
                >
                  Didn’t receive any OTP?{" "}
                  {resendTimer > 0 ? (
                    <span>Resend OTP in {resendTimer} seconds</span>
                  ) : (
                    <a href="#" onClick={resetOtp}>
                      Resend OTP
                    </a>
                  )}
                </div>

                <div className={global.form_group}>
                  <input
                    type="submit"
                    value="Verify"
                    className={global.submit_primary_btn}
                  />
                </div>
              </form>
            </>
          )}

          {otpState === "failure" && (
            <div className={UserRegistrations.unsuccessful}>
              <div className={global.text_center}>
                <i className={global.unsuccessful_icon}></i>
              </div>
              <h2>Unsuccessful!</h2>
              <div
                className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}
              >
                You've reached the maximum number of OTP attempts. Please try
                again in 24 hours.
              </div>
              <div className={`${global.form_group} ${global.mar_top_8}`}>
                <input
                  type="submit"
                  value="Click to Continue"
                  className={global.submit_primary_btn}
                  onClick={handleFailedSuccessState}
                />
              </div>
            </div>
          )}

          {otpState === "success" && (
            <div className={UserRegistrations.successful}>
              <div className={global.text_center}>
                <i className={global.successful_icon}></i>
              </div>
              <h2>Successful Sign Up!</h2>
              <div
                className={`${global.mar_top_16} ${global.mar_bottom_16} ${global.text_center}`}
              >
                Thank you for choosing us. We have sent you a link via email and
                SMS to complete your onboarding process. Kindly check your inbox
                or{" "}
                <a
                  href={onboardingLink}
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Click here
                </a>{" "}
                to complete the process.
              </div>

              <div className={`${global.form_group} ${global.mar_top_8}`}>
                <input
                  type="submit"
                  value="Click to Continue"
                  className={global.submit_primary_btn}
                  onClick={handleFailedSuccessState}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MobileOTP;
