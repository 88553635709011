const bankDetails = [
  { bank_name: "AXIS Bank", ifsc_prefix: "UTIB", image: "AXISBANK.png" },
  { bank_name: "Bandhan Bank", ifsc_prefix: "BDBL", image: "BANDHANBNK.png" },
  { bank_name: "Bank of Baroda", ifsc_prefix: "BARB", image: "BANKBARODA.png" },
  { bank_name: "Bank of India", ifsc_prefix: "BKID", image: "BANKOFINDIA.png" },
  { bank_name: "Canara Bank", ifsc_prefix: "CNRB", image: "CANBK.png" },
  { bank_name: "Central Bank of India", ifsc_prefix: "CBIN", image: "CENTRALBK.png" },
  { bank_name: "City Union Bank", ifsc_prefix: "CIUB", image: "CityUnionBN.png" },
  { bank_name: "CSB Bank", ifsc_prefix: "CSBK", image: "CSBBANK.png" },
  { bank_name: "DCB Bank", ifsc_prefix: "DCBL", image: "DCBBank.png" },
  { bank_name: "Dhanlaxmi Bank", ifsc_prefix: "DLXB", image: "dhnlxmi.png" },
  { bank_name: "Federal Bank", ifsc_prefix: "FDRL", image: "FederalBank.png" },
  { bank_name: "HDFC Bank", ifsc_prefix: "HDFC", image: "HDFCBank.png" },
  { bank_name: "ICICI Bank", ifsc_prefix: "ICIC", image: "ICICI Bank.png" },
  { bank_name: "IDBI Bank", ifsc_prefix: "IBKL", image: "IDBI-Bank.png" },
  { bank_name: "IDFC First Bank", ifsc_prefix: "IDFB", image: "IDFCFIRSTB.png" },
  { bank_name: "Indian Bank", ifsc_prefix: "IDIB", image: "INDIANB.png" },
  { bank_name: "Indian Overseas Bank", ifsc_prefix: "IOBA", image: "indianoverseasBank.png" },
  { bank_name: "IndusInd Bank", ifsc_prefix: "INDB", image: "INDUSINDBK.png" },
  { bank_name: "Jammu & Kashmir Bank", ifsc_prefix: "JAKA", image: "jammu-kashmir-bank.png" },
  { bank_name: "Karur Vysya Bank", ifsc_prefix: "KVBL", image: "KARURVYSYA.png" },
  { bank_name: "Kotak Mahindra Bank", ifsc_prefix: "KKBK", image: "KOTAKBANK.png" },
  { bank_name: "Karnataka Bank", ifsc_prefix: "KARB", image: "KTKBANK..png" },
  { bank_name: "Bank of Maharashtra", ifsc_prefix: "MAHB", image: "MAHABANK.png" },
  { bank_name: "Nainital Bank", ifsc_prefix: "NTBL", image: "Nainitalbank.png" },
  { bank_name: "Punjab National Bank", ifsc_prefix: "PUNB", image: "PNB.png" },
  { bank_name: "Punjab & Sind Bank", ifsc_prefix: "PSIB", image: "PSB.png" },
  { bank_name: "RBL Bank", ifsc_prefix: "RATN", image: "RBLBANK.png" },
  { bank_name: "South Indian Bank", ifsc_prefix: "SIBL", image: "southindianbank.png" },
  { bank_name: "State Bank of India", ifsc_prefix: "SBIN", image: "state-bank-of-india.png" },
  { bank_name: "Tamilnad Mercantile Bank", ifsc_prefix: "TMBL", image: "Tamilnad.png" },
  { bank_name: "UCO Bank", ifsc_prefix: "UCBA", image: "UCO Bank.png" },
  { bank_name: "Union Bank of India", ifsc_prefix: "UBIN", image: "UNIONBANK.png" },
  { bank_name: "YES Bank", ifsc_prefix: "YESB", image: "YESBANK.png" },
];

const getBankLogoUrl = (imageName) =>
  `https://lead-user-docs.s3.ap-south-1.amazonaws.com/bank-logos/${imageName}`;

export { bankDetails, getBankLogoUrl };
