import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Box,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import fetcher from "../../utils/fetcher";
import { PageUrls } from "../../utils/constants";
import { getLocation } from "../../utils/getLocation";
import { ApiUrls } from "../../utils/constants";
import Loader from '../../utils/Loader';

const ESignClientAgreement = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { name, aadherNo, phoneNo, pageOpenTime } = params
  const aadhaar = aadherNo
  const [isRadioSelected, setIsRadioSelected] = useState(false);
  const [isSended, setIsSended] = useState(false)
  const [otpAadharData, setOtpAadharData] = useState(null);
  const [aadharError, setAadharError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isSended) {
      navigate(
        generatePath(PageUrls.OTP_VERIFICATION, { aadherNo: aadherNo, phoneNo: phoneNo, refId: otpAadharData.otpReferenceID, pageOpenTime: pageOpenTime, }),
      )
    }
  }, [isSended])
  const sendOtpToAadhaar = async (aadhaarNo) => {
    try {
      setAadharError("");
      setLoading(true)
      const location = await getLocation();
      if (!aadhaarNo) {
        console.log('Aadhar number is not provided');
      }
      const payload = {
        aadhaarNumber: aadhaarNo,
        latitude: location.latitude,
        longitude: location.longitude,
        consent: "Y"
      };
      const resOtp = await fetcher.post(ApiUrls.LEAD_GENRATE_AADHAR_OTP, payload);

      if (resOtp?.status === 200 && resOtp?.response.status === 200) {
        const data = resOtp.response.response.data;
        // Save the OTP data in state
        setOtpAadharData({
          otpReferenceID: data.otpReferenceID,
          aadhaarNumber: aadhaarNo,
          status: resOtp.response.status
        });
        setIsSended(true)
      } else {
        setAadharError(resOtp?.response?.message || resOtp?.message);
        console.error('Failed to send OTP')
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    } finally {
      setLoading(false);
    }
  };
  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: name,
      authType: "Two-factor Authentication",
      agreement: "",
    },
    onSubmit: async (values) => {
      await sendOtpToAadhaar(aadhaar)
    },
  });

  // Handle Radio Button Change
  const handleRadioChange = (event) => {
    formik.setFieldValue("agreement", event.target.value);
    setIsRadioSelected(true);
  };
  if (loading) return <Loader />;
  if (aadharError) return <Typography>{aadharError}</Typography>;
  return (
    <Box
      component="form"
      sx={{
        width: "400px",
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 4,
        p: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
      }}
      onSubmit={formik.handleSubmit}
    >
      {/* <Typography variant="h6" textAlign="center">
        E-Sign Client Agreement
      </Typography> */}

      {/* Non-editable Name Field */}
      <TextField
        label="Name as per Aadher"
        value={formik.values.name}
        InputProps={{ readOnly: true }}
        fullWidth
      />

      {/* Non-editable Authentication Type Field */}
      <TextField
        label="Authentication Type"
        value={formik.values.authType}
        InputProps={{ readOnly: true }}
        fullWidth
      />

      {/* Radio Button Group */}
      <RadioGroup
        name="agreement"
        value={formik.values.agreement}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="I Agree"
          control={<Radio />}
          label="By clicking this checkbox and the Sign Document button, I voluntarily agree to Aadhaar eSign the previewed document. I also agree to be redirected to an eSign Service Provider for carrying out an e authentication using Aadhaar e-KYC services for issuance of a Digital Signature Certificate I agree to accept such Certificate for its affixation to the previewed document."
        />
      </RadioGroup>

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
        <Button
          variant="outlined"
          onClick={() => navigate(-1)} // Go back to the previous page
        >
          Back
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={!isRadioSelected} // Disable if radio is not selected
        >
          Proceed to eSign
        </Button>
      </Box>
    </Box>
  );
};

export default ESignClientAgreement;
