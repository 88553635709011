import React, { useState, useEffect } from 'react'
import UserGstin from './UserGstin'
import UploadKycDocumentFinal from './UploadKycDocumentFinal'
import ResubmissionUserGstin from './ResubmissionUserGstin'
import ResubmissionUploadKycDocument from './ResubmissionUploadKycDocument'
import { useParams } from 'react-router-dom'
import fetcher from '../../../utils/fetcher'
import { ApiUrls } from '../../../utils/constants'

const ResubmissionModule = () => {
    const { token } = useParams();
    const [userData, setUserData] = useState(null)

    const fetchUserData = () => {
        const res = fetcher.get(ApiUrls.LEAD_REGISTER_DETAIL);
        setUserData(res.response);
    }

    useEffect(() => {
        if (token) {
            localStorage.setItem('auth', JSON.stringify({ token: token }));
        }
        fetchUserData();
    }, [])


    return (
        <>
            <div className={global.col_s_11}>
                <h3>
                    Resubmission Module
                </h3>
            </div>
            <ResubmissionUserGstin userData={userData} />
            <ResubmissionUploadKycDocument userData={userData} />;
        </>
    )
}

export default ResubmissionModule