import React, { useState , useEffect} from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import fetcher from '../../utils/fetcher';
import { ApiUrls } from '../../utils/constants';
import { set } from 'date-fns';
import Loader from '../../utils/Loader'
import { bankDetails, getBankLogoUrl } from '../../utils/bankDetails'; // Import bank details and logo URL function





  
const FundRequest = () => {
  const [success, setSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formValues, setFormValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchTransactions = async () => {

    try {
      setLoading(true); 
      const response = await fetcher.get(ApiUrls.USER_FUND_REQUEST);
      console.log('Full API response:', response.data); 
  
      
      const data = response.response;
      setTransactions(data || []); // Use the response array or an empty array as fallback
      
    } catch (err) {
      console.error('API Error:', err.response || err.message); // Log errors for debugging
      setError(err.response?.data?.message || 'An error occurred while fetching transactions');
    } finally {
      setLoading(false); // Ensure the loading spinner stops
    }
  };

  const fetchBanks = async () => {

    try {
      setLoading(true);
      const response = await fetcher.get(ApiUrls.FETCH_BANK_ACCOUNTS);
      console.log('Full API response:', response.data); 
  
      
      const data = response.response.banks;
      setBanks(data || []); // Use the response array or an empty array as fallback
      
    } catch (err) {
      console.error('API Error:', err.response || err.message); // Log errors for debugging
      setError(err.response?.data?.message || 'An error occurred while fetching transactions');
    } finally {
      setLoading(false); // Ensure the loading spinner stops
    }
  };
  useEffect(() => {
    fetchTransactions(); 
    fetchBanks();
  }, []); 

  const uniqueBankNames = [...new Set(banks.map(bank => bank.bankName))];
  const currentAccounts = banks.filter(bank => bank.bankName === uniqueBankNames[activeTab]);

  const getAllowedModes = () => {
    if (formik.values.bankAccount) {
      const selectedBank = banks.find(bank => `${bank.bankName} - ${bank.accountNumber}` === formik.values.bankAccount);
      return selectedBank ? selectedBank.allowedModes.split(',') : [];
    }
    return [];
  };

console.log(transactions , "transactions");

  const getBankLogo = (bankName) => {
    const bank = bankDetails.find(bank => bank.bank_name === bankName);
    return bank ? getBankLogoUrl(bank.image) : null;
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };
  const handlePreviewSubmit = async () => {
    const currentAccount = currentAccounts[0]; // Use the first account from the filtered list
    const fundRequest = {
      bankAccount: currentAccount.accountNumber, // Use account number
      paymentMode: formValues.paymentMode,
      amount: formValues.amount,
      transferDate: formValues.date.valueOf(),
      depositorMobile: formValues.mobileNumber,
      depositBranch: formValues.branch,
      utrReference: formValues.utr,
    };
  
    const formData = new FormData();
  
    // Append the fundRequest JSON as a Blob with application/json MIME type
    const jsonBlob = new Blob([JSON.stringify(fundRequest)], { type: 'application/json' });
    formData.append('fundRequest', jsonBlob);
  
    // Append the acknowledgement slip file
    formData.append('ackSlip', formValues.acknowledgementSlip);
  
    try {
      await fetcher.post(ApiUrls.SUBMIT_FUND_REQUEST, formData)
      
      setSuccess(true);
      formik.resetForm();
      setPreviewOpen(false);
    } catch (err) {
      setError(err.message);
    }
  };
  

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue('acknowledgementSlip', file);
    setSelectedFileName(file ? file.name : '');
  };

  const calculateChargeOrCommission = (amount, mode) => {
    const selectedMode = banks.flatMap(bank => bank.bankPaymentMode).find(paymentMode => paymentMode.mode === mode);
    if (!selectedMode) return 0;
  
    const { type, value } = selectedMode;
    return type === 1 ? value : (amount * value) / 100;
  };
  
  const getChargeOrCommissionLabel = (mode) => {
    const selectedMode = banks.flatMap(bank => bank.bankPaymentMode).find(paymentMode => paymentMode.mode === mode);
    return selectedMode && selectedMode.chargeOrCommission === 2 ? 'Charge' : 'Commission';
  };

  const formik = useFormik({
    initialValues: {
      amount: '',
      utr: '',
      date: dayjs(),
      mobileNumber: '',
      branch: '',
      remarks: '',
      paymentMode: '',
      bankAccount: '', 
      acknowledgementSlip: null,
      chargeOrCommission: 0, 
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .min(10000, 'Amount must be at least ₹10,000.00')
        .max(10000000, 'Amount cannot exceed ₹1,00,00,000.00')
        .required('Amount is required'),
      utr: Yup.string().required('UTR/Reference Number is required'),
      date: Yup.date().required('Transfer date is required'),
      mobileNumber: Yup.string()
        .matches(/^\d{10}$/, 'Enter a valid 10-digit mobile number')
        .required('Mobile number is required'),
      branch: Yup.string().required('Deposit branch is required'),
      paymentMode: Yup.string().required('Payment mode is required'), // Add validation for paymentMode
      bankAccount: Yup.string().required('Bank account is required'), // Add validation for bankAccount
      remarks: Yup.string(),
    }),
    onSubmit: (values) => {
      const isUtrDuplicate = transactions.some((txn) => txn.utr === values.utr);
      if (isUtrDuplicate) {
        formik.setFieldError('utr', 'The mentioned UTR already exists.');
        return;
      }
      setFormValues(values);
      setPreviewOpen(true);
    },
    validate: (values) => {
      const errors = {};
      return errors;
    },
  });

  useEffect(() => {
    if (formik.values.amount && formik.values.paymentMode) {
      const chargeOrCommission = calculateChargeOrCommission(formik.values.amount, formik.values.paymentMode);
      formik.setFieldValue('chargeOrCommission', chargeOrCommission, false);
    }
  }, [formik.values.amount, formik.values.paymentMode]);

  return (
    <Grid container spacing={2} padding={2}>
      {loading && <Loader />}
      {/* Left Section */}
      <Grid item xs={12} md={4}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Typography variant="h6">New Fund Request</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Bank Account</InputLabel>
              <Select
                name="bankAccount"
                value={formik.values.bankAccount}
                onChange={formik.handleChange}
                error={formik.touched.bankAccount && Boolean(formik.errors.bankAccount)}
              >
                {banks.map((bank, index) => (
                  <MenuItem key={index} value={`${bank.bankName} - ${bank.accountNumber}`}>
                    {`${bank.bankName} - ${bank.accountNumber}`}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.bankAccount && formik.errors.bankAccount && (
                <Typography color="error">{formik.errors.bankAccount}</Typography>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Payment Mode</InputLabel>
              <Select
                name="paymentMode"
                value={formik.values.paymentMode}
                onChange={formik.handleChange}
                error={formik.touched.paymentMode && Boolean(formik.errors.paymentMode)}
              >
                {getAllowedModes().map((mode, index) => (
                  <MenuItem key={index} value={mode}>
                    {mode}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.paymentMode && formik.errors.paymentMode && (
                <Typography color="error">{formik.errors.paymentMode}</Typography>
              )}
            </FormControl>
            <TextField
              fullWidth
              label="Amount"
              variant="outlined"
              margin="normal"
              name="amount"
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
            />
            {formik.values.amount && formik.values.paymentMode && (
              <Typography variant="body2" color="textSecondary">
                {getChargeOrCommissionLabel(formik.values.paymentMode)}: 
                <span style={{ color: 'green' }}>
                  {formik.values.chargeOrCommission}
                </span>
              </Typography>
            )}
            <TextField
              fullWidth
              label="UTR/Reference Number"
              variant="outlined"
              margin="normal"
              name="utr"
              value={formik.values.utr}
              onChange={formik.handleChange}
              error={formik.touched.utr && Boolean(formik.errors.utr)}
              helperText={formik.touched.utr && formik.errors.utr}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth margin="normal">
              <DatePicker
                label="Date of Transfer/Deposit"
                value={formik.values.date}
                onChange={(newValue) => formik.setFieldValue('date', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                )}
              />
            </LocalizationProvider>
            <TextField
              fullWidth
              label="Mobile Number of Depositor (Optional)"
              variant="outlined"
              margin="normal"
              name="mobileNumber"
              value={formik.values.mobileNumber}
              onChange={formik.handleChange}
              error={formik.touched.mobileNumber && Boolean(formik.errors.mobileNumber)}
              helperText={formik.touched.mobileNumber && formik.errors.mobileNumber}
            />
            <TextField
              fullWidth
              label="Deposit Branch (Optional)"
              variant="outlined"
              margin="normal"
              name="branch"
              value={formik.values.branch}
              onChange={formik.handleChange}
              error={formik.touched.branch && Boolean(formik.errors.branch)}
              helperText={formik.touched.branch && formik.errors.branch}
            />
            <input
              accept="image/*"
              type="file"
              name="acknowledgementSlip"
              onChange={handleFileChange}
              style={{ display: 'none' }}
              id="acknowledgementSlip"
            />
            <label htmlFor="acknowledgementSlip">
              <Button variant="contained" color="primary" component="span" fullWidth>
                Upload Deposit Slip
              </Button>
            </label>
            {selectedFileName && (
              <Typography variant="body2" color="textSecondary">
                Selected file: {selectedFileName}
              </Typography>
            )}
            {formik.touched.acknowledgementSlip && formik.errors.acknowledgementSlip && (
              <Typography color="error">{formik.errors.acknowledgementSlip}</Typography>
            )}
            <TextField
              fullWidth
              label="Remarks"
              variant="outlined"
              margin="normal"
              name="remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
            />
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </form>
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper elevation={3} style={{ padding: '16px' }}>
          <Tabs value={activeTab} onChange={handleTabChange} textColor="primary" indicatorColor="primary">
            {uniqueBankNames.map((bankName, index) => (
              <Tab key={index} label={<img src={getBankLogo(bankName)} alt={bankName} style={{ height: '24px' }} />} />
            ))}
          </Tabs>
          {currentAccounts.map((account, index) => (
            <Box key={index} padding={2}>
              <Typography>
                <strong>Account Holder:</strong> {account.accountHolderName}
              </Typography>
              <Typography>
                <strong>Account Number:</strong> {account.accountNumber}
              </Typography>
              <Typography>
                <strong>Account Type:</strong> {account.accountType}
              </Typography>
              <Typography>
                <strong>IFSC Code:</strong> {account.ifscCode}
              </Typography>
              <Typography>
                <strong>Branch:</strong> {account.branchName}
              </Typography>
              <Typography>
                <strong>Minimum Amount:</strong> {account.minimumDeposit}
              </Typography>
              <Typography>
                <strong>Allowed Modes:</strong> {account.allowedModes}
              </Typography>
            </Box>
          ))}
        </Paper>
      </Grid>

      {/* Table Below Both Sections */}
      <Grid item xs={12}>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Transaction ID</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Branch</TableCell>
                <TableCell>Remarks</TableCell>
                <TableCell>Executer's Remark</TableCell>
                <TableCell>Execution Date & Time</TableCell>
                <TableCell>Transfer Date</TableCell>
                <TableCell>Branch UTR</TableCell>
                <TableCell>Commission/Charge</TableCell>
                <TableCell>Deposit Slip</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {transactions.map((transaction, index) => (
  <TableRow key={index}>
    <TableCell>{dayjs(transaction.transferDate).format('YYYY-MM-DD')}</TableCell>
    <TableCell>{transaction.id}</TableCell>
    <TableCell>{transaction.amount}</TableCell>
    <TableCell>{transaction.depositBranch}</TableCell>
    <TableCell>{transaction.remarks}</TableCell>
    <TableCell>{transaction.verified}</TableCell>
    <TableCell>{dayjs(transaction.updatedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
    <TableCell>{dayjs(transaction.transferDate).format('YYYY-MM-DD')}</TableCell>
    <TableCell>{transaction.utrReference}</TableCell>
    <TableCell>{transaction.commissionCharge || 'N/A'}</TableCell>
    <TableCell>{transaction.acknowledgementSlip || 'N/A'}</TableCell>
    <TableCell>{transaction.isActive}</TableCell>
  </TableRow>
))}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>

      {/* Success Snackbar */}
      <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
        <Alert onClose={() => setSuccess(false)} severity="success">
          Fund request submitted successfully!
        </Alert>
      </Snackbar>
      {/* Preview Dialog */}
      <Dialog open={previewOpen} onClose={handlePreviewClose}>
        <DialogTitle>Preview Fund Request</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please review your fund request details before submitting.
          </DialogContentText>
          <Typography><strong>Amount:</strong> {formValues?.amount}</Typography>
          <Typography><strong>UTR/Reference Number:</strong> {formValues?.utr}</Typography>
          <Typography><strong>Date:</strong> {formValues?.date?.format('YYYY-MM-DD')}</Typography>
          <Typography><strong>Mobile Number:</strong> {formValues?.mobileNumber}</Typography>
          <Typography><strong>Branch:</strong> {formValues?.branch}</Typography>
          <Typography><strong>Remarks:</strong> {formValues?.remarks}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewClose} color="primary">
            Modify
          </Button>
          <Button onClick={handlePreviewSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default FundRequest;
