import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import global from "../../../assets/scss/global.module.scss";
import useronboard from "./UserOnboard.module.scss";
import { ApiUrls, LeadSteps } from "../../../utils/constants";
import fetcher from "../../../utils/fetcher";
import Loader from "../../../utils/Loader";
const ResubmissionUploadKycDocument = ({ userData }) => {
    const [documents, setDocuments] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false); // Track if upload is in progress
    const location = useLocation();
    const navigate = useNavigate();
    const imageDocuments = documents.filter((doc) => doc.fileType === "image");
    const pdfDocuments = documents.filter((doc) => doc.fileType === "pdf");
    const constitutionType = location.state?.constitutionType;
    const MAX_FILE_SIZE = 400 * 1024;
    const [fileErrors, setFileErrors] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [file, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState({});


    // Fetch Documents based on constitutionType
    const fetchDocuments = async (constitutionType) => {
        try {
            const res = await fetcher.post(ApiUrls.LEAD_DOC_OF_CONSTITUTION_TYPE, {
                constitutionType,
            });
            if (res?.status === 200) {
                setDocuments(res.response);
            } else {
                console.error("Error fetching documents:", res.message);
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
        }
    };

    // Fetch user details when component mounts
    useEffect(() => {
        if (userData?.leadBusinessDetails?.constitutionType) {
            fetchDocuments(userData.leadBusinessDetails.constitutionType);
        }

        if (
            userData?.leadBusinessDocuments &&
            userData.leadBusinessDocuments.length > 0
        ) {
            const filteredDocuments = userData.leadBusinessDocuments.reduce(
                (acc, e) => {
                    acc[e.docId] = {
                        docId: e.docId,
                        fileName: e.fileName,
                        bucketUrl: e.bucketUrl,
                    };
                    return acc;
                },
                {}
            );
            setUploadedFiles(filteredDocuments);
        }
    }, [userData]);


    // Upload documents with progress
    const handleFileChange = async (e, docId) => {
        const file = e.target.files[0];
        if (file) {
            setFileErrors((prevErrors) => ({
                ...prevErrors,
                [docId]: "",
            }));
            if (file.size > MAX_FILE_SIZE) {
                setFileErrors((prevErrors) => ({
                    ...prevErrors,
                    [docId]: "Upload size should be less than 400 kb",
                }));
                return;
            }
            uploadDocuments(file, docId);
        } else {
            setUploadedFiles((prevState) => ({
                ...prevState,
                [docId]: "No file chosen",
            }));
        }
    };

    const uploadDocuments = async (file, docId) => {
        const formData = new FormData();
        formData.append("leadUserId", userData.leadUserId);
        formData.append("file", file);
        formData.append("docId", docId);

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const percent = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadStatus((prevState) => ({
                        ...prevState,
                        [docId]: {
                            ...prevState[docId],
                            progress: percent,
                        },
                    }));
                }
            },
        };

        try {
            setUploadStatus((prevState) => ({
                ...prevState,
                [docId]: {
                    ...prevState[docId],
                    isUploading: true,
                },
            }));
            const res = await fetcher.postFile(
                ApiUrls.LEAD_UPLOAD_DOCS,
                formData,
                config
            );
            if (res?.status === 200) {
                setUploadedFiles((prevState) => ({
                    ...prevState,
                    [docId]: {
                        ...prevState[docId],
                        docId: file.name,
                        fileName: file.name,
                        bucketUrl: res?.response.bucketUrl,
                    },
                }));
                console.log("File uploaded successfully:", res.message);
            } else {
                console.error("Error uploading file:", res.message);
            }
        } catch (error) {
            console.error("Error making upload request:", error);
        } finally {
            setUploadStatus((prevState) => ({
                ...prevState,
                [docId]: {
                    ...prevState[docId],
                    isUploading: false,
                },
            }));
        }
    };

    // Handle the modal open/close
    const openUrlInModal = (file) => {
        setUrl(file);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setUrl("");
    };

    // Handle the next step in the form
    const handleNext = async () => {
        setLoading(true);
        const resOtp = await fetcher.get(
            `${ApiUrls.LEAD_UPDATE_REGISTER_STEP}/${LeadSteps.kycdoc}`
        );
        if (resOtp?.status === 200) {
           // nextStep(3);
        } else {
            alert(resOtp?.message);
        }
        setLoading(false);
    };

    const allFilesUploaded = documents.every((doc) =>
        uploadedFiles.hasOwnProperty(doc.id)
    );

    // Function to render the file preview based on file type (image or PDF)
    const renderPreview = (fileUrl) => {
        // Check if the file is a PDF or an image
        if (fileUrl.endsWith(".pdf")) {
            return (
                <object
                    data={fileUrl}
                    type="application/pdf"
                    width="100%"
                    height="600px"
                >
                    <p>
                        Your browser does not support PDFs.{" "}
                        <a href={fileUrl}>Download the PDF</a>.
                    </p>
                </object>
            );
        } else {
            return (
                <iframe
                    src={fileUrl}
                    className={global.thickbox_whtie_iframe}
                    title="File Preview"
                    style={{ maxWidth: "100%", height: "340px" }}
                />
            );
        }
    };

    return (
        <>
            <div className={useronboard.confirm_details}>
                {loading && <Loader />}
                <div className={`${global.row} ${useronboard.confirm_details_heading}`}>
                    <div className={global.col_s_11}>
                        <h3>
                            <i className={useronboard.text_number}>A</i> KYC Documents Upload
                        </h3>
                    </div>
                    <div className={`${global.col_s_1} ${global.pos_rel}`}>
                        <i className={global.verification_icon}></i>
                        <i className={global.verify_success_icon}></i>
                    </div>
                </div>

                <div className={useronboard.confirm_details_form}>
                    <div className={useronboard.constitution_type}>
                        Constitution Type
                        <span>{userData?.leadBusinessDetails?.constitutionType}</span>
                    </div>

                    {/* Image Documents Section */}
                    {imageDocuments.length > 0 && (
                        <>
                            <div className={useronboard.images_format_bg}>
                                <span className={useronboard.text_center}>
                                    Upload jpeg/png/jpg.
                                </span>
                            </div>

                            {imageDocuments.map((doc) => (
                                <div className={useronboard.upload_photo} key={doc.id}>
                                    <div className={global.row}>
                                        <div className={global.col_s_4}>
                                            <h4>{doc.documentName}</h4>
                                        </div>

                                        {isOpen && (
                                            <div className={global.thickbox_gray_bg}>
                                                <div className={global.thickbox_whtie_box}>
                                                    <button onClick={closeModal}>
                                                        <i className={global.close_svg_icon}></i>
                                                    </button>
                                                    <h2>URL Preview</h2>
                                                    {renderPreview(file)}{" "}
                                                    {/* Render appropriate preview */}
                                                </div>
                                            </div>
                                        )}

                                        <div className={`${global.col_s_8} ${global.text_right}`}>
                                            <div className={useronboard.upload_photo_icon}>
                                                <div className={global.row}>
                                                    {/* Progress Column */}
                                                    {uploadStatus[doc.id]?.isUploading && (
                                                        <div>
                                                            <progress
                                                                value={uploadStatus[doc.id]?.progress || 0}
                                                                max="100"
                                                                style={{ width: "100%" }}
                                                            />
                                                            <span>
                                                                <strong>{uploadStatus[doc.id]?.progress || 0}%</strong>
                                                            </span>
                                                        </div>
                                                    )}
                                                    {/* View Column */}
                                                    <div className={global.col_12}>
                                                        {uploadedFiles[doc.id] ? (
                                                            <>
                                                                <div className={global.row}>
                                                                    <div className={global.col_s_2}> {uploadedFiles[doc.id].fileName !== "No file chosen" && (
                                                                        <div
                                                                            className={`${global.form_group}`}
                                                                            onClick={() => openUrlInModal(uploadedFiles[doc.id].bucketUrl)}
                                                                            style={{ cursor: "pointer", marginRight: "8px" }}
                                                                            title="View File"
                                                                        >
                                                                            <i className={global.image_view_icon}></i>
                                                                        </div>
                                                                    )}</div>
                                                                    <div className={global.col_s_10}>       <div>{uploadedFiles[doc.id].fileName}</div> </div>


                                                                </div>


                                                            </>
                                                        ) : (
                                                            "No file chosen"
                                                        )}
                                                    </div>
                                                    <div className={global.col_12}>
                                                        <input
                                                            type="file"
                                                            id={`file_${doc.id}`}
                                                            accept="image/*"
                                                            onChange={(e) => handleFileChange(e, doc.id)}
                                                            style={{ display: "none" }}
                                                        />
                                                        <label
                                                            htmlFor={`file_${doc.id}`}
                                                            className={useronboard.file_label}
                                                        >
                                                            Choose a file
                                                        </label>
                                                        {fileErrors[doc.id] && (
                                                            <div
                                                                className={`${global.error_message} ${global.text_right}`}
                                                            >
                                                                {fileErrors[doc.id]}
                                                            </div>
                                                        )}
                                                    </div>







                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    {/* PDF Documents Section */}
                    {pdfDocuments.length > 0 && (
                        <>
                            <div className={useronboard.images_format_bg}>
                                <span className={useronboard.text_center}>Upload PDF</span>
                            </div>

                            {pdfDocuments.map((doc) => (
                                <div className={useronboard.upload_photo} key={doc.id}>
                                    <div className={global.row}>
                                        <div className={global.col_s_6}>
                                            <h4>{doc.documentName}</h4>
                                        </div>
                                        {uploadStatus[doc.id]?.isUploading && (
                                            <div>
                                                <progress
                                                    value={uploadStatus[doc.id]?.progress || 0}
                                                    max="100"
                                                    style={{ width: "100%" }}
                                                />
                                                <span>
                                                    <strong>{uploadStatus[doc.id]?.progress || 0}%</strong>
                                                </span>
                                            </div>
                                        )}

                                        {isOpen && (
                                            <div className={global.thickbox_gray_bg}>
                                                <div className={global.thickbox_whtie_box}>
                                                    <button onClick={closeModal}>
                                                        <i className={global.close_svg_icon}></i>
                                                    </button>
                                                    <h2>URL Preview</h2>
                                                    {renderPreview(file)}{" "}
                                                    {/* Render appropriate preview */}
                                                </div>
                                            </div>
                                        )}

                                        <div className={`${global.col_s_6} ${global.text_right}`}>
                                            <div className={useronboard.upload_photo_icon}>
                                                <span className={useronboard.file_name}>
                                                    {uploadedFiles[doc.id] ? (
                                                        <>
                                                            {uploadedFiles[doc.id].fileName !==
                                                                "No file chosen" && (
                                                                    <span
                                                                        className={useronboard.view_icon}
                                                                        onClick={() =>
                                                                            openUrlInModal(
                                                                                uploadedFiles[doc.id].bucketUrl
                                                                            )
                                                                        }
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            marginRight: "8px",
                                                                        }}
                                                                        title="View File"
                                                                    >
                                                                        <i className={global.image_view_icon}></i>
                                                                    </span>
                                                                )}
                                                            {uploadedFiles[doc.id].fileName}
                                                        </>
                                                    ) : (
                                                        "No file chosen"
                                                    )}
                                                </span>
                                                <input
                                                    type="file"
                                                    id={`file_${doc.id}`}
                                                    accept=".pdf"
                                                    onChange={(e) => handleFileChange(e, doc.id)}
                                                    style={{ display: "none" }}
                                                />
                                                <label
                                                    htmlFor={`file_${doc.id}`}
                                                    className={useronboard.file_label}
                                                >
                                                    Choose a file
                                                </label>
                                                {fileErrors[doc.id] && (
                                                    <div
                                                        className={`${global.error_message} ${global.text_right}`}
                                                    >
                                                        {fileErrors[doc.id]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}

                    <div className={useronboard.submit_text_bg_}>
                        <input
                            type="submit"
                            className={`${allFilesUploaded
                                ? global.submit_primary_btn
                                : global.submit_seconday_btn
                                } ${global.display_inline_blk}`}
                            value="Submit"
                            onClick={handleNext}
                            disabled={!allFilesUploaded}
                        />
                        <span className={global.info_message}>
                            <i className={global.info_icon}></i>
                            <span className={global.info_hide}>
                                Upload size of the image should be less than 400 kb.
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResubmissionUploadKycDocument