import { Button, Grid, FormControl, Typography, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import * as Yup from "yup";
import Loader from "../../utils/Loader"
import fetcher from "../../utils/fetcher";
import { PageUrls, ApiUrls } from "../../utils/constants";
import DOMPurify from 'dompurify';
import ESignAlreadyDoneModal from "../../components/modals/ESignAlreadyDoneModal";
const ESignClientRequestDetail = ({ isPreview = false, aadharData, aadhaarImageUrl }) => {
    const navigate = useNavigate();
    const params = useParams();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [userData, setUserData] = useState()
    const [content, setContent] = useState()
    const [pageOpenTime, setPageOpenTime] = useState(null);
    const [alreadySigned, setAlreadySigned] = useState(false)
    const date = new Date();
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[date.getMonth()];
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;
    useEffect(() => {
        let timeString = `${date.getDate()}.${monthName}.${date.getFullYear()} ${time}`;
        setPageOpenTime(timeString);
    }, []);

    const { token } = params

    const fetchUserData = async () => {
        try {
            setLoading(true)
            const data = await fetcher.get(ApiUrls.LEAD_REGISTER_DETAIL);
            if (data?.status === 200) {
                setUserData(data.response);
                const leadUserId = data.response.leadUserId
                const res = await fetcher.get(`${ApiUrls.GET_LEAD_SAVE_ESIGN_CONTENT}?leadUserId=${leadUserId}`)
                if (res?.status === 200) setContent(res.response)
                if (data.response?.esignStatus === "Matured") { setAlreadySigned(true) }
            } else {
                setError("Failed to fetch lead details.");
            }

        } catch (error) {
            console.error("Error fetching user data:", error);
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isPreview) {
            fetchUserData();
        }
    }, [isPreview])
    useEffect(() => {
        if (token) {
            localStorage.removeItem("auth")
            localStorage.setItem('auth', JSON.stringify({ token: token }));
        }
        fetchUserData();
    }, [token]);


    const validationSchema = Yup.object({
        policyAccepted: Yup.boolean()
            .oneOf([true], 'You must accept the policy'),
    });
    const handleSubmit = (values) => {
        navigate(
            generatePath(PageUrls.CLIENT_AGREEMENT, {
                name: userData.authorizedPersonName,
                aadherNo: userData.leadAadhaarDetails.aadharNumber,
                phoneNo: userData.mobile,
                pageOpenTime: pageOpenTime,
            }),
        )
    }
    const formManager = useFormik({
        initialValues: {
            policyAccepted: false,
        },
        validationSchema,
        onSubmit: (values) => handleSubmit(values),

    });
    const sanitizedContent = DOMPurify.sanitize(content);
    if (loading) return <Loader />;
    if (error) return <Typography>{error}</Typography>;
    return (
        <>
            {/* {!isPreview ? (<div> */}
            {/* <ESignAgreement clientData={clientData} adminData={adminData} /> */}
            {alreadySigned ? <ESignAlreadyDoneModal open={alreadySigned} /> : (
                <>
                    <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                    <form onSubmit={formManager.handleSubmit}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                paddingRight: "0px !important",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                position: "relative",
                            }}
                        >
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginTop: "20px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                            }}
                        >
                            <FormControl component="fieldset" error={formManager.touched.policyAccepted && Boolean(formManager.errors.policyAccepted)}>
                                <FormLabel component="legend">Policy Agreement</FormLabel>
                                <RadioGroup
                                    name="policyAccepted"
                                    value={formManager.values.policyAccepted.toString()}
                                    onChange={(e) => {
                                        const value = e.target.value === 'true';
                                        formManager.setFieldValue("policyAccepted", value);
                                    }}
                                >
                                    <FormControlLabel value="true" control={<Radio />} label={`I ${userData?.authorizedPersonName}, hereby declare that I am signing this agreement in full awareness, consciousness, and without any form of coercion. I acknowledge and agree to all the terms outlined in the agreement and 
understand that my digital signature, as well as my geographical location, IP address, and browser details, have been recorded as part of the signing process.`} />
                                </RadioGroup>
                                {formManager.touched.policyAccepted && formManager.errors.policyAccepted && (
                                    <div style={{ color: 'red', fontSize: '12px' }}>{formManager.errors.policyAccepted}</div>
                                )}
                            </FormControl>
                        </Grid>
                        <Button type="submit" disabled={!formManager.isValid}>Proceed</Button>
                    </form>
                </>
            )}


            {/* </div>) : (<ESignAgreement clientData={clientData} adminData={adminData} isPreview={isPreview} aadharData={aadharData} aadhaarImageUrl={aadhaarImageUrl} />)} */}
        </>
    )
}

export default ESignClientRequestDetail